import React, {Component} from 'react';
import {PageView} from "../tracking/util"

class WhoWeAre extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    componentDidMount() {
        PageView();
    }

    render() {
        return (
            <div>
                <div className="py-9 py-lg-0 bg-cover bg-size--cover" style={{"min-height": "350px"}}></div>
                <section className="section-half-rounded bg-transparent pt-6 pb-10 mt-n17 zindex-99">
                    <div className="jumbotron section-inner left-0 rounded-top-right bg-primary overflow-hidden col-xl-10 col-lg-11"></div>
                    <div className="container">
                        <div className="row row-grid align-items-center">
                            <div className="col-xl-9 col-lg-11 offset-lg-1 offset-xl-2">

                                <h1 className="h2 text-white mt-3 mb-6 pr-5">
                                    Who we are
                                </h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-half-rounded bg-transparent py-5 mt-n10 zindex-100">
                    <div className="jumbotron section-inner right-0 rounded-0 overflow-hidden col-xl-10 col-lg-12"></div>
                    <div className="container pb-6">
                        <div className="row row-grid align-items-center">
                            <div className="col-xl-8 col-lg-10 offset-xl-2 offset-lg-1">
                                <article>
                                    <p>
                                        Diana Woodall, MD founded Empower Integrative Psychiatry with the goal to provide compassionate, individualized, and non-judgemental care.
                                        Dr.
                                        Woodall has trained extensively in psychopharmacology and in various psychotherapeutic modalities including psychodynamic, interpersonal,
                                        ACT/CBT, and humanistic approaches. She is experienced in seamlessly combining medication management and psychotherapy, but also enjoys
                                        working
                                        with patients who are just seeking medication management or who are already in therapy and need a provider to manage their medications. She
                                        has
                                        a special interest in mood and anxiety disorders, OCD, and integrative medicine. Her goal in working with patients is to empower each
                                        individual
                                        to re-discover and harness their unique strengths and to foster self-actualization and resiliency.
                                    </p>

                                    <h5 className="h3">Licenses</h5>
                                    <ul className="list-unstyled list-bullet">
                                        <li className="py-2">
                                            Georgia
                                        </li>
                                        <li className="py-2">
                                            Washington
                                        </li>

                                    </ul>
                                    <h5>Board Certified</h5>
                                    <p>
                                        American Board of Psychiatry & Neurology
                                    </p>
                                    <h5>Residency Training</h5>
                                    <p>
                                        Emory University Department of Psychiatry, Atlanta, GA
                                    </p>
                                    <h5>Education</h5>
                                    <p>
                                        M.D. - University of North Carolina - Chapel Hill
                                        <br/>
                                        B.A. Sociology - Emory University
                                    </p>

                                    <br/>
                                    <br/>
                                    <br/>

                                    <h2 className="h2" id={"whyus"}>Why us?</h2>

                                    <h5>Individualized treatment</h5>
                                    <p>
                                        <strong>
                                            We view every patient as a unique individual, not a diagnosis. Treatment recommendations may include medication, psychotherapy,
                                            medical/diagnostic labwork and imaging, nutrition, exercise, or other wellness focused approaches - all highly individualized based on
                                            your
                                            presenting symptoms, lifestyle, history, and preferences.
                                        </strong>
                                    </p>
                                    <h5>Collaboration</h5>
                                    <p>
                                        <strong>
                                            We believe your preferences should play a significant role in guiding your treatment. We respect your autonomy and work collaboratively
                                            to
                                            meet your goals.
                                        </strong>
                                    </p>

                                    <h5>Convenience</h5>
                                    <p>
                                        <strong>
                                            With appointments conducted via telemedicine, be seen where you are, not where the doctor is.
                                        </strong>
                                    </p>

                                    <h5>Confidentiality</h5>
                                    <p>
                                        <strong>
                                            Appointments occur over a HIPAA compliant video platform designed to protect your privacy. There are no waiting rooms, and no
                                            health insurance companies dictating patient care.
                                        </strong>
                                    </p>
                                    <h5>On-time appointments</h5>
                                    <p>
                                        <strong>
                                            We understand that your life is hectic. We commit to starting your appointment on time so you're never waiting around for
                                            your doctor.
                                        </strong>
                                    </p>


                                </article>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    };
}

export default WhoWeAre;