import React from 'react'
import {Route, Link} from "react-router-dom";
import {Mail, Phone, Calendar, Printer, ExternalLink} from 'react-feather';

const Home = () => {
    return (
        <div>
            <section className="slice py-5">
                <div className="container">
                    <div className="row row-grid align-items-center">
                        <div className="col-12 col-md-5 col-lg-6 order-md-2">

                            <figure className="w-100">
                                <img alt="Image placeholder" src={require("../assets/img/brand/logo_head.png")}
                                     className="img-fluid mw-md-120"></img>
                            </figure>
                        </div>
                        <div className="col-12 col-md-7 col-lg-6 order-md-1 pr-md-5">

                            <h1 className="display-4 text-center text-md-left mb-3">
                                Comprehensive Psychiatric Care, <strong className="text-primary">Anywhere</strong>
                            </h1>
                            {/*<p className="lead text-center text-md-left red" style={{color: "red"}}><b>*/}
                            {/*    Our appointment scheduling system is currently down. If you wish to schedule an appointment please go to book appointments, find a time and send a text to the number below with your <text style={{textDecoration: "underline"}}>NAME, NUMBER, EMAIL and TIME requested</text>. We apologize for the inconvenience.</b>*/}
                            {/*</p>*/}
                            <p className="lead text-center text-md-left text-muted">
                                Empowering you on a journey of wellness and healing
                            </p>

                            <p className="lead text-center text-md-left text-muted">
                                <small>Virtual appointments from the comfort of your home or office in Georgia &
                                    Washington
                                </small>
                            </p>

                            <div className="text-center text-md-left mt-5">
                                <Link to="/book-appointment" className="btn btn-primary btn-icon">
                                        <span className="btn-inner--icon">
                                            <Calendar/>
                                        </span>
                                    <span className="btn-inner--text">Book an Appointment</span>
                                </Link>

                            </div>
                            <div className="text-center text-md-left mt-5">
                                <a href="https://phr.charmtracker.com/login.sas?FACILITY_ID=5e4f9a0821d5970445ace34e2182316cecc0dac7302b0fce2e62fe42203ae47d7a68c4657f2ee8b2" target={"_blank"} rel="noreferrer noopener" className="btn btn-primary btn-icon">
                                        <span className="btn-inner--icon">
                                            <ExternalLink/>
                                        </span>
                                    <span className="btn-inner--text">Patient Portal (existing only)</span>
                                </a>

                            </div>
                            <div className="text-center text-md-left mt-5">
                                <div>
                                    <a href="tel:4044822877" target={"_blank"} rel="noreferrer noopener" className="btn btn-primary btn-neutral btn-icon">
                                        <span className="btn-inner--icon">
                                            <Phone/>
                                        </span>
                                        <span className="btn-inner--text">(404) 482-2877</span>
                                    </a>
                                </div>
                                <div className="mt-2">
                                    <a href="fax:2403488633" target={"_blank"} rel="noreferrer noopener" className="btn btn-primary btn-neutral btn-icon">
                                        <span className="btn-inner--icon">
                                            <Printer/>
                                        </span>
                                        <span className="btn-inner--text">(240) 348-8633</span>
                                    </a>
                                </div>
                                <div className="mt-2">
                                    <a href="mailto:dianawoodallmd@eipsych.com"
                                       className="btn btn-primary btn-neutral btn-icon" target={"_blank"}>
                                        <span className="btn-inner--icon"><Mail/></span><span
                                        className="btn-inner--text">dianawoodallmd@eipsych.com</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default Home;