import React, {Component} from 'react';
import {PageView} from "../tracking/util"
import {HashLink} from 'react-router-hash-link';

class BookAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            showInputs: false,
            checkboxFifteenMinutes: false,
            checkboxInitialAppointment: false,
            checkboxInsurance: false,
            checkboxNotEmergency: false,
            // checkboxDoctorPatient: false,
            checkboxEasternTZ: false,
            checkboxCredit: false
        };

        this.appointmentClicked = this.appointmentClicked.bind(this);
        this.consentClicked = this.consentClicked.bind(this);

    }

    componentDidMount() {
        PageView();
    }


    appointmentClicked(event) {
        console.log(event.target);

        this.setState({checkboxInitialAppointment: false, checkboxFifteenMinutes: false, checkboxEasternTZ: false});

        if (event.target.id === "checkboxInitialAppointment") {
            this.setState({checkboxInitialAppointment: true});
        } else if (event.target.id === "checkboxFifteenMinutes") {
            this.setState({checkboxFifteenMinutes: true});
        } else if (event.target.id === "checkboxEasternTZ") {
            this.setState({checkboxEasternTZ: true});
        } else if (event.target.id === "checkboxInsurance") {
            this.setState({checkboxInsurance: true});
        }

        this.setState({showInputs: true});
    }

    consentClicked(event) {

        if (event.target.id === 'checkboxNotEmergency') {

            this.setState({checkboxNotEmergency: !this.state.checkboxNotEmergency});

            if (!this.state.checkboxNotEmergency && this.state.checkboxEasternTZ && this.state.checkboxInsurance && this.state.checkboxCredit) {
                this.setState({showInputs: true});
            } else {
                this.setState({showInputs: false});
            }
        }

        // else if (event.target.id === 'checkboxDoctorPatient') {
        //
        //     this.setState({checkboxDoctorPatient: !this.state.checkboxDoctorPatient});
        //
        //     if (!this.state.checkboxDoctorPatient && this.state.checkboxNotEmergency && this.state.checkboxEasternTZ && this.state.checkboxInsurance) {
        //         this.setState({showInputs: true});
        //     } else {
        //         this.setState({showInputs: false});
        //     }
        // }

        else if (event.target.id === 'checkboxEasternTZ') {

            this.setState({checkboxEasternTZ: !this.state.checkboxEasternTZ});

            if (!this.state.checkboxEasternTZ && this.state.checkboxNotEmergency && this.state.checkboxInsurance && this.state.checkboxCredit) {
                this.setState({showInputs: true});
            } else {
                this.setState({showInputs: false});
            }
        }

        else if (event.target.id === 'checkboxInsurance') {

            this.setState({checkboxInsurance: !this.state.checkboxInsurance});

            if (!this.state.checkboxInsurance && this.state.checkboxNotEmergency && this.state.checkboxEasternTZ && this.state.checkboxCredit) {
                this.setState({showInputs: true});
            } else {
                this.setState({showInputs: false});
            }
        }
        else if (event.target.id === 'checkboxCredit') {

            this.setState({checkboxCredit: !this.state.checkboxCredit});

            if (!this.state.checkboxCredit && this.state.checkboxInsurance && this.state.checkboxNotEmergency && this.state.checkboxEasternTZ) {
                this.setState({showInputs: true});
            } else {
                this.setState({showInputs: false});
            }
        }
    }

    render() {
        return (
            <div className="slice slice-sm bg-section-secondary">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row row-grid align-items-center">
                                                <div className="col-lg-12">
                                                    <div className="media align-items-center">
                                                        <div className="media-body">
                                                            <p className="text-muted lh-150 text-sm mb-0">
                                                                <div className="">                                                                    
                                                                    <br></br>
                                                                    <label><b>EXISTING PATIENTS:</b> please <b>DO NOT USE THIS SCHEDULING TOOL.</b> Instead, send me a message via the patient portal <a href='https://phr.charmtracker.com/' target='_blank'>(https://phr.charmtracker.com/)</a> to schedule a follow-up appointment.</label>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <label><b>NEW PATIENTS:</b> Please check ALL the boxes below to pull up the scheduling calendar.</label>
                                                                    <br></br>
                                                                    <br></br>
                                                                </div>
                                                                
                                                                <div className="">
                                                                    <label>If you believe you are experiencing a medical or psychiatric emergency, including suicidal or homicidal thoughts, do not use this service. Instead call 911 or go to your closest emergency room. </label>
                                                                </div>
                                                                <div className="form-group custom-control custom-checkbox">
                                                                    <input type="checkbox" className="checkbox form-control custom-control-input" id="checkboxNotEmergency"
                                                                           value={this.state.checkboxNotEmergency}
                                                                           onClick={this.consentClicked}/>
                                                                    <label className="custom-control-label" for="checkboxNotEmergency">
                                                                        This request is not an emergency. I wish to proceed.</label>

                                                                </div>
                                                                <br/>
                                                                <div className="">
                                                                    <label>Before scheduling, please review the <HashLink to="/fees">Fees & Insurance</HashLink> section of this website. </label>
                                                                </div>
                                                                <div className="form-group custom-control custom-checkbox">
                                                                    <input type="checkbox" className="checkbox form-control custom-control-input" id="checkboxInsurance"
                                                                           value={this.state.checkboxInsurance}
                                                                           onClick={this.consentClicked}/>
                                                                    <label className="custom-control-label" for="checkboxInsurance">
                                                                        I understand that Dr. Woodall is out-of-network for my insurance plan and that insurance reimbursement may vary.</label>

                                                                </div>

                                                                <div className="form-group custom-control custom-checkbox">
                                                                    <input type="checkbox" className="form-control custom-control-input" id="checkboxEasternTZ"
                                                                           value={this.state.checkboxEasternTZ}
                                                                           onClick={this.consentClicked}/>
                                                                    <label className="custom-control-label" for="checkboxEasternTZ">
                                                                        I understand the appointment times listed below are in Eastern Standard Time.</label>
                                                                </div>
                                                                <div className="form-group custom-control custom-checkbox">
                                                                    <input type="checkbox" className="form-control custom-control-input" id="checkboxCredit"
                                                                           value={this.state.checkboxCredit}
                                                                           onClick={this.consentClicked}/>
                                                                    <label className="custom-control-label"
                                                                           htmlFor="checkboxCredit"><strong>If you are booking a 70 min Initial Consultation ONLY: Credit card information is required to confirm your appointment.
                                                                        You will not be charged until the time of your appointment. Before selecting a time below, please <HashLink to="/save-card" target="_blank">click here</HashLink> to save credit card details. </strong></label>
                                                                </div> 
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={this.state.showInputs ? "visible" : "invisible"}>
                                        <h5 className="mb-3">Pick a Time</h5>
                                        <iframe width="100%" height="1000"
                                                src="https://ehr2.charmtracker.com/publicCal.sas?method=getCal&digest=ecc0dac7302b0fcefe414daee98c64ef73ab7d114cc04fd624ae357ce7b03b3713ba92ed45c9a6b888e9d84fabf34710debf17855dc5f624"
                                                style={{"overflow": "hidden"}} frameborder="0"></iframe>
                                    </div>
                                    {/*<div className={this.state.showInputs ? "visible" : "invisible"}>*/}
                                    {/*<h5 className="mb-3">Your information</h5>*/}
                                    {/*<form>*/}
                                    {/*<div className="row">*/}
                                    {/*<div className="col-md-6">*/}
                                    {/*<div className="form-group">*/}
                                    {/*<label className="form-control-label">First name</label>*/}
                                    {/*<input className="form-control" type="text" placeholder="Enter your first name"/>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-md-6">*/}
                                    {/*<div className="form-group">*/}
                                    {/*<label className="form-control-label">Last name</label>*/}
                                    {/*<input className="form-control" type="text" placeholder="Also your last name"/>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    {/*<div className="row align-items-center">*/}
                                    {/*<div className="col-md-6">*/}
                                    {/*<div className="form-group">*/}
                                    {/*<label className="form-control-label">Location</label>*/}
                                    {/*<select className="custom-select">*/}
                                    {/*<option disabled selected>Select option</option>*/}
                                    {/*<option value="1">Georgia</option>*/}
                                    {/*<option value="2">California</option>*/}
                                    {/*<option value="3">Washington</option>*/}
                                    {/*<option value="4">Mississippi</option>*/}
                                    {/*<option value="5">Utah</option>*/}
                                    {/*</select>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    {/*<div className="row">*/}
                                    {/*<div className="col-md-6">*/}
                                    {/*<div className="form-group">*/}
                                    {/*<label className="form-control-label">Email</label>*/}
                                    {/*<input className="form-control" type="email" placeholder="name@exmaple.com"/>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-md-6">*/}
                                    {/*<div className="form-group">*/}
                                    {/*<label className="form-control-label">Phone</label>*/}
                                    {/*<input className="form-control" type="text" placeholder="123 456 7890"/>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    {/*<div className="row row-grid align-items-center">*/}
                                    {/*<div className="col-lg-8">*/}

                                    {/*<div className="media-body">*/}
                                    {/*<p className="text-muted lh-150 text-sm mb-0">*/}
                                    {/*<div className="">*/}
                                    {/*<label>If you believe you are experiencing a medical or psychiatric*/}
                                    {/*emergency, including suicidal or homicidal thoughts, side*/}
                                    {/*effects of any of your medications, or any other issues for which you need an immediate response, do not use*/}
                                    {/*this*/}
                                    {/*service. Instead call 911 or go to your closest emergency room. </label>*/}
                                    {/*</div>*/}
                                    {/*<div className="form-group custom-control custom-checkbox">*/}
                                    {/*<input type="checkbox" className="checkbox form-control custom-control-input" id="checkboxNotEmergency"*/}
                                    {/*value={this.state.checkboxNotEmergency}*/}
                                    {/*onClick={this.consentClicked}/>*/}
                                    {/*<label className="custom-control-label" for="checkboxNotEmergency">*/}
                                    {/*This request is not an emergency or urgent*/}
                                    {/*matter. I wish to proceed.</label>*/}

                                    {/*</div>*/}
                                    {/*<div className="form-group custom-control custom-checkbox">*/}
                                    {/*<input type="checkbox" className="form-control custom-control-input" id="checkboxDoctorPatient"*/}
                                    {/*value={this.state.checkboxDoctorPatient}*/}
                                    {/*onClick={this.consentClicked}/>*/}
                                    {/*<label className="custom-control-label" for="checkboxDoctorPatient">*/}
                                    {/*I acknowledge this request for an appointment doesn't*/}
                                    {/*establish a doctor/patient relationship and that all first appointments are consultation only.</label>*/}
                                    {/*</div>*/}
                                    {/*</p>*/}
                                    {/*</div>*/}

                                    {/*</div>*/}
                                    {/*</div>*/}


                                    {/*<div className="mt-3">*/}
                                    {/*<button type="button" className="btn btn-sm btn-primary">Save</button>*/}
                                    {/*</div>*/}
                                    {/*</form>*/}
                                    {/*</div>*/}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export default BookAppointment;