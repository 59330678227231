import React, {Component} from 'react';

import {BrowserRouter as Router, Route} from "react-router-dom";
import {HashLink} from 'react-router-hash-link';

import './styles/quick-website.css';
// Pages
import Home from "./components/home"
import Services from "./components/services"
import Fees from "./components/fees"
import BookAppointment from "./components/book-appointment"
import WhoWeAre from "./components/who-we-are"
import SaveCard from "./components/save-card"
// Tracking
import {initGA, PageView} from './tracking/util';
//Feather
import {DollarSign, FileText, Heart, Layout, Smile, X} from 'react-feather';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarMenuVisible: false
        };

        this.toggleRef = React.createRef();

        this.toggleMenu = this.toggleMenu.bind(this);
    }


    toggleMenu(event) {
        console.log("opening menu");

        const style = window.getComputedStyle ? getComputedStyle(this.toggleRef.current, null) : this.toggleRef.current.currentStyle;

        this.setState({sidebarMenuVisible: !this.state.sidebarMenuVisible});

    }

    componentDidMount() {
        initGA('UA-180803363-1');
        PageView();
    }

    render() {
        return (
            <Router>
                <header className="" id="header-main">
                    <nav className="navbar navbar-main navbar-expand-lg navbar-light" id="navbar-main">
                        <div className="container">
                            <HashLink className="navbar-brand" to={"/"}>
                                <img alt="Image placeholder" src={require("./assets/img/brand/logo.png")} id="navbar-logo"></img>
                            </HashLink>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-main-collapse" aria-controls="navbar-main-collapse"
                                    aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggleMenu}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div ref={this.toggleRef}
                                 className={`${(this.state.sidebarMenuVisible) ? 'collapse navbar-collapse navbar-collapse-overlay show' : 'collapse navbar-collapse navbar-collapse-overlay'}`}
                                 id="navbar-main-collapse">
                                <div className="position-relative">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-main-collapse" aria-controls="navbar-main-collapse"
                                            aria-expanded="false" aria-label="Toggle navigation" onClick={this.toggleMenu}>
                                        <X/>
                                    </button>
                                </div>
                                <ul className="navbar-nav ml-lg-auto">
                                    <li className="nav-item nav-item-spaced dropdown dropdown-animate" data-toggle="hover">
                                        <HashLink className="nav-link" data-toggle="dropdown" to={"/who-we-are"} aria-haspopup="true" aria-expanded="false" onClick={this.toggleMenu}>
                                            Introduction
                                        </HashLink>
                                        <div className="dropdown-menu dropdown-menu-md p-0">
                                            <div className="row no-gutters">
                                                <div className="">
                                                    <div className="dropdown-body">
                                                        <div className="list-group list-group-flush">
                                                            <div className="list-group-item border-0">
                                                                <div className="media d-flex">
                                                                    <span className="h6">
                                                                        <Smile/>
                                                                    </span>
                                                                    <div className="media-body ml-2">
                                                                        <HashLink to={"/who-we-are"} className="d-block h6 mb-0" onClick={this.toggleMenu}>Who we are</HashLink>
                                                                        {/*<small className="text-sm text-muted mb-0">Get to know us</small>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="list-group-item border-0">
                                                                <div className="media d-flex">
                                                                    <span className="h6">
                                                                        <Layout/>
                                                                    </span>
                                                                    <div className="media-body ml-2">
                                                                        <HashLink to={"/who-we-are#whyus"} className="d-block h6 mb-0" onClick={this.toggleMenu}>Why Us?</HashLink>
                                                                        {/*<small className="text-sm text-muted mb-0">A starting point for any scenario.</small>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item nav-item-spaced dropdown dropdown-animate" data-toggle="hover">
                                        <HashLink className="nav-link" data-toggle="dropdown" to="/services" aria-haspopup="true" aria-expanded="false" onClick={this.toggleMenu}>
                                            Services
                                        </HashLink>
                                        <div className="dropdown-menu dropdown-menu-md p-0">
                                            <div className="row no-gutters">
                                                <div className="">
                                                    <div className="dropdown-body dropdown-body-right bg-dropdown-secondary h-100">
                                                        <div className="list-group list-group-flush">
                                                            <div className="list-group-item bg-transparent border-0 px-0 py-2">
                                                                <div className="media d-flex">
                                                                    <span className="h6">
                                                                        <Layout/>
                                                                    </span>
                                                                    <div className="media-body ml-2">
                                                                        <HashLink to="/services" className="d-block heading h6 mb-0" onClick={this.toggleMenu}>Services</HashLink>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="list-group-item bg-transparent border-0 px-0 py-2">
                                                                <div className="media d-flex">
                                                                    <span className="h6">
                                                                        <Heart/>
                                                                    </span>
                                                                    <div className="media-body ml-2">
                                                                        <HashLink to="/services#issuestreated" className="d-block h6 mb-0" onClick={this.toggleMenu}>Issues we treat</HashLink>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item nav-item-spaced dropdown dropdown-animate" data-toggle="hover">
                                        <HashLink className="nav-link" data-toggle="dropdown" to="/fees" aria-haspopup="true" aria-expanded="false" onClick={this.toggleMenu}>
                                            Fees & Insurance
                                        </HashLink>
                                        <div className="dropdown-menu dropdown-menu-md p-0">
                                            <div className="row no-gutters">
                                                <div className="">
                                                    <div className="dropdown-body dropdown-body-right bg-dropdown-secondary h-100">
                                                        <div className="list-group list-group-flush">
                                                            <div className="list-group-item bg-transparent border-0 px-0 py-2">
                                                                <div className="media d-flex">
                                                                    <span className="h6">
                                                                        <DollarSign/>
                                                                    </span>
                                                                    <div className="media-body ml-2">
                                                                        <HashLink to="/fees#fees" className="d-block h6 mb-0" onClick={this.toggleMenu}>Fees</HashLink>
                                                                        <small className="text-sm text-muted mb-0">Prices for our services</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="list-group-item bg-transparent border-0 px-0 py-2">
                                                                <div className="media d-flex">
                                                                    <span className="h6">
                                                                        <FileText/>
                                                                    </span>
                                                                    <div className="media-body ml-2">
                                                                        <HashLink to="/fees#insurance" className="d-block h6 mb-0" onClick={this.toggleMenu}>Insurance</HashLink>
                                                                        <small className="text-sm text-muted mb-0" >Information regarding insurance</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="navbar-nav align-items-lg-center d-none d-lg-flex ml-lg-auto">
                                    <li className="nav-item">
                                        <HashLink to="/book-appointment" className="btn btn-sm btn-primary btn-icon ml-3"><span
                                            className="btn-inner--text">Book an Appointment</span></HashLink>

                                    </li>
                                </ul>
                                <div className="d-lg-none px-4 text-center">
                                    <HashLink to="/book-appointment" className="btn btn-block btn-sm btn-primary" onClick={this.toggleMenu}>Book an Appointment</HashLink>

                                </div>
                            </div>
                        </div>
                    </nav>
                </header>


                {/*ROUTES*/}

                <Route exact path="/" component={Home}/>
                <Route path="/services" component={Services}/>
                <Route path="/fees" component={Fees}/>
                <Route path="/book-appointment" component={BookAppointment}/>
                <Route path="/who-we-are" component={WhoWeAre}/>
                <Route path="/save-card" component={SaveCard}/>

                {/*<footer className="position-relative" id="footer-main">*/}
                {/*<div className="footer pt-lg-7 footer-dark bg-dark">*/}
                {/*<div className="shape-container shape-line shape-position-top shape-orientation-inverse">*/}
                {/*<svg width="2560px" height="100px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px"*/}
                {/*y="0px" viewBox="0 0 2560 100" style={{"enableBackground": "new 0 0 2560 100"}} xmlSpace="preserve" className="">*/}
                {/*<polygon points="2560 0 2560 100 0 100"></polygon>*/}
                {/*</svg>*/}
                {/*</div>*/}
                {/*<div className="container pt-4">*/}
                {/*<div className="row justify-content-center">*/}
                {/*<div className="col-lg-12">*/}
                {/*<div className="row align-items-center">*/}
                {/*<div className="col-lg-7">*/}
                {/*<h3 className="text-secondary mb-2">Brilliant solutions for your ideas</h3>*/}
                {/*<p className="lead mb-0 text-white opacity-8">*/}
                {/*Build modern looking websites fast and easy using Quick.*/}
                {/*</p>*/}
                {/*</div>*/}
                {/*<div className="col-lg-5 text-lg-right mt-4 mt-lg-0">*/}
                {/*<a href="docs/index.html" className="btn btn-white btn-icon my-2" target="_blank">*/}
                {/*<span className="btn-inner--icon">*/}
                {/*<Book className="text-primary"/>*/}
                {/*</span>*/}
                {/*<span className="btn-inner--text">Documentation</span>*/}
                {/*</a>*/}
                {/*<a href="https://themes.getbootstrap.com/product/quick-website-ui-kit-2/" className="btn btn-primary my-2 ml-0 ml-sm-3" target="_blank">*/}
                {/*Purchase now*/}
                {/*</a>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<hr className="divider divider-fade divider-dark my-5"></hr>*/}
                {/*<div className="row">*/}
                {/*<div className="col-lg-4 mb-5 mb-lg-0">*/}
                {/*<a href="index.html">*/}
                {/*<img alt="Image placeholder" src={require("./assets/img/brand/light.svg")} id="footer-logo"></img>*/}
                {/*</a>*/}
                {/*<p className="mt-4 text-sm opacity-8 pr-lg-4">Webpixels attempts to bring the best development experience to designers and developers by*/}
                {/*offering the tools needed for having a quick and solid start in most web projects.</p>*/}
                {/*<ul className="nav mt-4">*/}
                {/*<li className="nav-item">*/}
                {/*<a className="nav-link pl-0" href="https://dribbble.com/webpixels" target="_blank">*/}
                {/*<i className="fab fa-dribbble"></i>*/}
                {/*</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*<a className="nav-link" href="https://github.com/webpixels" target="_blank">*/}
                {/*<i className="fab fa-github"></i>*/}
                {/*</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*<a className="nav-link" href="https://www.instagram.com/webpixelsofficial" target="_blank">*/}
                {/*<i className="fab fa-instagram"></i>*/}
                {/*</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*<a className="nav-link" href="https://www.facebook.com/webpixels" target="_blank">*/}
                {/*<i className="fab fa-facebook"></i>*/}
                {/*</a>*/}
                {/*</li>*/}
                {/*</ul>*/}
                {/*</div>*/}
                {/*<div className="col-lg-2 col-6 col-sm-4 ml-lg-auto mb-5 mb-lg-0">*/}
                {/*<h6 className="heading mb-3">Account</h6>*/}
                {/*<ul className="list-unstyled">*/}
                {/*<li><a href="#">Profile</a></li>*/}
                {/*<li><a href="#">Settings</a></li>*/}
                {/*<li><a href="#">Billing</a></li>*/}
                {/*<li><a href="#">Notifications</a></li>*/}
                {/*</ul>*/}
                {/*</div>*/}
                {/*<div className="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">*/}
                {/*<h6 className="heading mb-3">About</h6>*/}
                {/*<ul className="list-unstyled">*/}
                {/*<li><a href="#">Services</a></li>*/}
                {/*<li><a href="#">Pricing</a></li>*/}
                {/*<li><a href="#">Contact</a></li>*/}
                {/*<li><a href="#">Careers</a></li>*/}
                {/*</ul>*/}
                {/*</div>*/}
                {/*<div className="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">*/}
                {/*<h6 className="heading mb-3">Company</h6>*/}
                {/*<ul className="list-unstyled">*/}
                {/*<li><a href="#">Community</a></li>*/}
                {/*<li><a href="#">Help center</a></li>*/}
                {/*<li><a href="#">Support</a></li>*/}
                {/*</ul>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<hr className="divider divider-fade divider-dark my-4"></hr>*/}
                {/*<div className="row align-items-center justify-content-md-between pb-4">*/}
                {/*<div className="col-md-6">*/}
                {/*<div className="copyright text-sm font-weight-bold text-center text-md-left">*/}
                {/*&copy; 2020 Empower Integrative Psychiatry. All rights reserved*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div className="col-md-6">*/}
                {/*<ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0">*/}
                {/*<li className="nav-item">*/}
                {/*<a className="nav-link" href="#">*/}
                {/*Terms*/}
                {/*</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*<a className="nav-link" href="#">*/}
                {/*Privacy*/}
                {/*</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*<a className="nav-link" href="#">*/}
                {/*Cookies*/}
                {/*</a>*/}
                {/*</li>*/}
                {/*</ul>*/}
                {/*</div>*/}
                {/*</div>*/}

                {/*</div>*/}
                {/*</div>*/}
                {/*</footer>*/}
            </Router>
        )
    }
}

export default App;
