import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {PageView} from "../tracking/util"

class Fees extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.scheduleNowClicked = this.scheduleNowClicked.bind(this);
    }

    scheduleNowClicked(event) {
        console.log(event.target);

        this.setState({checkboxInitialAppointment: false, checkboxFifteenMinutes: false});

        if (event.target.id === "checkboxInitialAppointment") {
            this.setState({checkboxInitialAppointment: true});
        } else if (event.target.id === "checkboxFifteenMinutes") {
            this.setState({checkboxFifteenMinutes: true});
        }

        this.setState({showInputs: true});
    }

    componentDidMount() {
        PageView();
    }

    render() {
        return (
            <div>
                <section className="slice slice-lg pb-0 bg-section-secondary">
                    <div className="container position-relative zindex-100">
                        <div className="row mb-5 justify-content-center text-center">
                            <div className="col-lg-7 col-md-9">
                                <h3 className="h2 lh-170"><strong>Fees</strong></h3>
                            </div>
                        </div>
                        <div className="row justify-content-center text-center" id={"fees"}>
                            <div className="col-lg-7 col-md-9">
                                {/*<h5 className="h5 ">Fees</h5>*/}
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-10 ">
                                <div className="pricing-container">
                                    <div className="text-center">
                                    </div>
                                    <div className="row pricing mb-3 shadow-none">

                                        <div className="col-lg-4">
                                            <div className="card card-pricing border-0 text-center px-3">
                                                <div className="card-header py-5 border-0 delimiter-bottom">
                                                    <div className="h1 text-center mb-0" data-pricing-value="429">$<span className="price font-weight-bolder">429</span></div>
                                                    <span className="h6 text-muted">Initial Consultation<br/>(70 minutes)</span>
                                                </div>
                                                <div className="card-body">
                                                    <ul className="list-unstyled text-sm mb-4">
                                                        <li>The initial consultation is a comprehensive psychiatric and wellness evaluation. We will discuss your reasons for
                                                            seeking treatment, your medical and psychiatric history, and other aspects of your life. We will then collaborate on
                                                            treatment goals and recommendations.
                                                        </li>
                                                    </ul>
                                                    <Link to={"/book-appointment"} className="btn btn-sm btn-warning hover-translate-y-n3 hover-shadow-lg mb-3">Book now</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card card-pricing border-0 text-center px-3">
                                                <div className="card-header py-5 border-0 delimiter-bottom">
                                                    <div className="h1 text-center mb-0" data-pricing-value="199">$<span className="price font-weight-bolder">199</span></div>
                                                    <span className="h6 text-muted">Focus Session<br/>(25 minutes)</span>
                                                </div>
                                                <div className="card-body">
                                                    <ul className="list-unstyled text-sm mb-4">
                                                        <li>Focus sessions are used to follow-up and monitor your response to treatment after your initial evaluation.
                                                            They provide targeted follow-up such as medication management or brief supportive psychotherapy.
                                                        </li>
                                                    </ul>
                                                    {/*<Link to={"/book-appointment"} className="btn btn-sm btn-warning hover-translate-y-n3 hover-shadow-lg mb-3">Book now</Link>*/}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card card-pricing text-center px-3 shadow border-0">
                                                <div className="card-header py-5 border-0 delimiter-bottom">
                                                    <div className="h1 text-center mb-0" data-pricing-value="289">$<span className="price font-weight-bolder">289</span></div>
                                                    <span className="h6 text-muted">Full Session<br/>(45 minutes)</span>
                                                </div>
                                                <div className="card-body">
                                                    <ul className="list-unstyled text-sm mb-4">

                                                        <li>Full sessions are also used to follow-up and monitor your response to treatment after your initial evaluation.
                                                            Most patients who opt for full sessions do so because they provide time for in-depth psychotherapy. In addition to psychotherapy,
                                                            full sessions can be used to evaluate your response to medications, lifestyle interventions,
                                                            or to discuss any other concerns that may require additional time or support.
                                                        </li>

                                                    </ul>
                                                    {/*<Link to={"/book-appointment"} className="btn btn-sm btn-warning hover-translate-y-n3 hover-shadow-lg mb-3">Book now</Link>*/}
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="col-lg-4">*/}
                                        {/*    <div className="card card-pricing bg-primary border-0 text-center px-3">*/}
                                        {/*        <div className="card-header py-5 border-0 delimiter-bottom">*/}
                                        {/*            <div className="h1 text-white text-center mb-0" data-pricing-value="2360">$<span className="price font-weight-bolder">245</span></div>*/}
                                        {/*            <span className="h6 text-white">Full Sessions (weekly)<br/>(50 minutes)</span>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="card-body">*/}
                                        {/*            <ul className="list-unstyled text-white text-sm opacity-8 mb-4">*/}
                                        {/*                <li>Full sessions are used to follow-up and monitor your response to treatment after your initial evaluation. Most patients*/}
                                        {/*                    who opt for full sessions do so because they provide time for in-depth psychotherapy. In addition to psychotherapy, full*/}
                                        {/*                    sessions can be used to evaluate your response to medications, lifestyle interventions, or to discuss any other concerns*/}
                                        {/*                    that may require additional time or support.*/}
                                        {/*                </li>*/}
                                        {/*            </ul>*/}
                                        {/*            /!*<Link to={"/book-appointment"} className="btn btn-sm btn-white hover-translate-y-n3 hover-shadow-lg mb-3">Book now</Link>*!/*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    {/*<div className="row pricing mb-3 shadow-none">*/}

                                    {/*    </div>*/}
                                        {/*<div className="col-lg-4">*/}
                                        {/*    <div className="card card-pricing text-center px-3 shadow border-0">*/}
                                        {/*        <div className="card-header py-5 border-0 delimiter-bottom">*/}
                                        {/*            <div className="h1 text-center mb-0" data-pricing-value="294">$<span className="price font-weight-bolder">275</span></div>*/}
                                        {/*            <span className="h6 text-muted">Couples Psycotherapy (50 minutes)</span>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="card-body">*/}
                                        {/*            <ul className="list-unstyled text-sm mb-4">*/}
                                        {/*            </ul>*/}
                                        {/*            <Link to={"/book-appointment"} className="btn btn-sm btn-warning hover-translate-y-n3 hover-shadow-lg mb-3">Book now</Link>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="shape-container shape-line shape-position-bottom">
                        <svg width="2560px" height="200px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px"
                             viewBox="0 0 2560 200" style={{"enable-background": "new 0 0 2560 100"}} xmlSpace="preserve" className="">
                            <polygon points="2560 0 2560 200 0 200"></polygon>
                        </svg>
                    </div>
                </section>
                <section className="slice slice-lg pb-0" id="insurance">


                </section>
                <section className="slice slice-lg" id="insurance">
                    <div className="container position-relative zindex-100">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <h3 className="d-inline-block mb-5"><br/>Insurance</h3>
                                <p className="lead lh-150 mb-0">
                                    I am an out of network provider and do not bill insurance directly. This allows my patients to maintain the utmost
                                    privacy and confidentiality in their mental health treatment.
                                    <br/>
                                    <br/>
                                    If you would like to submit an out-of-network claim for partial reimbursement from your insurance company, I can provide the necessary
                                    documentation at your request. However, patients are responsible for full payment at the time of service.</p>
                                <h3 className="d-inline-block mt-7 mb-5 text-highlight-warning">How to Obtain Insurance Reimbursement</h3>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="card py-4 shadow-lg hover-translate-y-n10 hover-shadow-lg">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-sm bg-warning rounded-circle icon-shape">
                                                            <span className="h6 mb-0">1</span>
                                                        </div>
                                                    </div>
                                                    <div className="pl-3">
                                                        <h5 className="h4 mb-0">Statement</h5>
                                                    </div>
                                                </div>
                                                <p className="mt-4 mb-0">
                                                    I will send you a written statement at the end of each month to show what you ALREADY paid at the time of each visit, with all
                                                    the
                                                    appropriate codes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card py-4 shadow-lg hover-translate-y-n10 hover-shadow-lg">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-sm bg-warning rounded-circle icon-shape">
                                                            <span className="h6 mb-0">2</span>
                                                        </div>
                                                    </div>
                                                    <div className="pl-3">
                                                        <h5 className="h4 mb-0">Send to insurance</h5>
                                                    </div>
                                                </div>
                                                <p className="mt-4 mb-0">This statement can be sent to your insurance company for reimbursement directly to you (though typically not
                                                    for
                                                    the full amount). You simply need to fill out your own insurance form, attach the statement I mailed to you, and send these to
                                                    your
                                                    insurance company.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="d-inline-block mt-7 mb-5 text-highlight-warning">How much will I be reimbursed?</h3>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="card py-4 shadow-lg hover-translate-y-n10 hover-shadow-lg">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-sm bg-warning rounded-circle icon-shape">
                                                            <span className="h6 mb-0">1</span>
                                                        </div>
                                                    </div>
                                                    <div className="pl-3">
                                                        <h5 className="h4 mb-0">Insurance Codes</h5>
                                                    </div>
                                                </div>
                                                <p className="mt-4 mb-0">
                                                    This varies widely between insurance plans. To obtain an estimate, contact your insurance company and ask about out-of-network
                                                    reimbursement for the following CPT codes:
                                                    <ul>
                                                        <li>Initial Consultation: 99215</li>
                                                        <li>Full session: 99214+90836 or 99213+90836</li>
                                                        <li>Focus session: 99214+90833 or 99213+90833</li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card py-4 shadow-lg hover-translate-y-n10 hover-shadow-lg">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="icon icon-sm bg-warning rounded-circle icon-shape">
                                                            <span className="h6 mb-0">2</span>
                                                        </div>
                                                    </div>
                                                    <div className="pl-3">
                                                        <h5 className="h4 mb-0">Out of Pocket Costs</h5>
                                                    </div>
                                                </div>
                                                <p className="mt-4 mb-0">
                                                    Your out of pocket cost will be equal to the session fee minus any insurance reimbursement.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    };
}

export default Fees;