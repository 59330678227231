import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {PageView} from "../tracking/util"

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.scheduleNowClicked = this.scheduleNowClicked.bind(this);
    }

    scheduleNowClicked(event) {
        console.log(event.target);

        this.setState({checkboxInitialAppointment: false, checkboxFifteenMinutes: false});

        if (event.target.id === "checkboxInitialAppointment") {
            this.setState({checkboxInitialAppointment: true});
        } else if (event.target.id === "checkboxFifteenMinutes") {
            this.setState({checkboxFifteenMinutes: true});
        }

        this.setState({showInputs: true});
    }

    componentDidMount() {
        PageView();
    }

    render() {
        return (
            <div>
                <section className="slice slice-lg pb-0 bg-section-secondary">
                    <div className="container position-relative zindex-100">
                        <div className="row mb-5 justify-content-center text-center">
                            <div className="col-lg-7 col-md-9">
                                <h3 className="h2 lh-170"><strong>Services</strong></h3>
                            </div>
                        </div>
                        <div className="row mb-5 justify-content-center text-center">
                            <div className="col-lg-4">
                                <ol>
                                    <li>Psychiatric Diagnosis & Evaluation</li>
                                    <li>Medication Management</li>
                                    <li>Pyschotherapy</li>
                                    {/*<li>Wellness</li>*/}
                                </ol>
                            </div>
                        </div>

                        <div className="row justify-content-center text-center" id={"issuestreated"}>
                            <div className="col-lg-7 col-md-9">
                                <h5 className="h5 lh-170">Issues We Treat</h5>
                            </div>
                        </div>

                        <div className="row mb-5 justify-content-center text-center">
                            <div className="col-lg-3">
                                <ul>
                                    <li>Depression</li>
                                    <li>Bipolar Disorder</li>
                                    <li>Schizophrenia</li>
                                    <li>Schizoaffective Disorder</li>
                                    <li>Psychosis</li>
                                    <li>Anxiety and Panic Attacks</li>
                                    <li>Obsessive Compulsive Disorder (OCD)</li>
                                    <li>Trauma and PTSD</li>
                                </ul>
                            </div>
                            <div className="col-lg-3">
                                <ul>
                                    <li>Borderline Personality Disorder</li>
                                    <li>ADD/ADHD</li>
                                    <li>Behavioral Problems</li>
                                    <li>Mood Disturbances</li>
                                    <li>Sleeping Difficulties</li>
                                    <li>Sexuality, Gender, and Identity concerns</li>
                                    <li>Medical and Health Concerns</li>
                                </ul>
                            </div>
                            <div className="col-lg-3">
                                <ul>
                                    <li>Grief and Loss</li>
                                    <li>Work and Career Issues</li>
                                    <li>Relationship Issues</li>
                                    <li>Stress Management</li>
                                    <li>Lifecycle and Transition issues</li>
                                    <li>Neglect, Emotional, Physical and Sexual abuses, and Emotional Invalidation</li>
                                </ul>
                            </div>


                        </div>

                    </div>
                    <div className="shape-container shape-line shape-position-bottom">
                        <svg width="2560px" height="200px" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px"
                             viewBox="0 0 2560 200" style={{"enable-background": "new 0 0 2560 100"}} xmlSpace="preserve" className="">
                            <polygon points="2560 0 2560 200 0 200"></polygon>
                        </svg>
                    </div>
                </section>

            </div>
        )
    };
}

export default Services;